<template>
  <!-- Header -->
  <div class="header" style="padding: 20px 40px; margin-bottom: 20px">
    <v-row>
      <img src="@/assets/unvergessen-logo-grey.png" alt="hallo" style="height: 50px">
      <v-spacer></v-spacer>
      <span style="padding-top: 14px; margin-right: 20px" v-if="user.lastLogin"><b>Dein Letzter Login:</b> {{ new Date(user.lastLogin.time).toLocaleString([], {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}) }} Uhr</span>
      <v-btn class="text--text grey3" width="200px" height="50px" style="text-transform: none; margin-right: 20px" @click="$router.push({ name: 'Dashboard' })" v-if="$route.name !== 'Dashboard'">Dashboard</v-btn>
      <v-btn class="error white--text" width="200px" height="50px" style="text-transform: none" @click="logout">Abmelden</v-btn>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    logout () {
      axios.post('/auth/logout')
        .then(() => {
          this.$store.dispatch('logout', {})
          this.$router.push({ name: 'Login' })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
