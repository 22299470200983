import axios from 'axios'

const state = {
  loggedIn: false,
  token: null,
  uId: null,
  tmp: {},
  tmpLogin: { username: '', pwd: '' }
}

const getters = {
  isLoggedIn: state => {
    return state.loggedIn
  },
  tmpLogin: state => {
    return state.tmpLogin
  }
}

const mutations = {
  resetTmpLogin: (state) => {
    state.tmpLogin = { username: '', pwd: '' }
  },
  setTmpLogin: (state, payload) => {
    state.tmpLogin = payload
  },
  login: (state, payload) => {
    state.loggedIn = true
    state.token = 'Bearer ' + payload.token
    axios.defaults.headers.common.Authorization = state.token
    localStorage.setItem('ewToken', payload.token)
    state.uId = payload.uId
    state.tmp = payload
  },
  logout: (state, payload) => {
    state.loggedIn = false
    state.token = null
    state.uId = null
    delete axios.defaults.headers.common.Authorization
    localStorage.removeItem('ewToken')
    state.tmp = payload
  },
  setLoggedIn: state => {
    state.loggedIn = true
  },
  updateToken: (state, payload) => {
    state.token = 'Bearer' + payload.data.token
    axios.defaults.headers.common.Authorization = 'Bearer ' + payload.data.token
    localStorage.setItem('ewToken', payload.data.token)
    state.loggedIn = true
  }
}

const actions = {
  login: (context, payload) => {
    context.dispatch('setRenewTimer', { time: 3500 })
    context.commit('login', payload)
  },
  logout: (context, payload) => {
    context.commit('logout', payload)
  },
  setLoggedIn: context => {
    context.commit('setLoggedIn')
  },
  renewAuth: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.get('/auth/renew', { params: { long: payload.keepMeLoggedIn } })
        .then(res => {
          context.dispatch('setRenewTimer', { time: 3500 })
          context.commit('updateToken', { data: res.data })
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setRenewTimer: (context, payload) => {
    setTimeout(() => {
      context.dispatch('renewAuth', {})
    }, (payload.time * 1000) - 60000)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
