const state = {
  method: '',
  art: '',
  frequency: '',
  userStatus: '',
  typeName: '',
  aboStatus: '',
  visibility: '',
  paymentStatus: '',
  memorialMailStatus: '',
  // crm post
  crmPostStatus: '',
  crmPostCycle: ''
}

const getters = {
  crmPostCycle: state => {
    return state.crmPostCycle
  },
  crmPostStatus: state => {
    return state.crmPostStatus
  },
  method: state => {
    return state.method
  },
  art: state => {
    return state.art
  },
  frequency: state => {
    return state.frequency
  },
  userStatus: state => {
    return state.frequency
  },
  typeName: state => {
    return state.typeName
  },
  aboStatus: state => {
    return state.aboStatus
  },
  visibility: state => {
    return state.visibility
  },
  paymentStatus: state => {
    return state.paymentStatus
  },
  memorialMailStatus: state => {
    return state.memorialMailStatus
  }
}

const mutations = {
  MapCrmPostCycle: (state, crmPostCycle) => {
    switch (crmPostCycle) {
      case 'daily':
        state.crmPostCycle = 'Täglich'
        break
      case 'biweekly':
        state.crmPostCycle = '2x pro Woche'
        break
      case 'weekly':
        state.crmPostCycle = 'Wöchentlich'
        break
      case 'monthly':
        state.crmPostCycle = 'Monatlich'
        break
      default:
        state.crmPostCycle = 'error'
    }
  },
  MapCrmPostStatus: (state, crmPostStatus) => {
    switch (crmPostStatus) {
      case 'active':
        state.crmPostStatus = 'Aktiv'
        break
      case 'inactive':
        state.crmPostStatus = 'Gratis-Variante'
        break
      case 'canceled':
        state.crmPostStatus = 'Gekündigt'
        break
      default:
        state.crmPostStatus = 'error'
    }
  },
  MapMethod: (state, method) => {
    switch (method) {
      case 'card':
        state.method = 'Kreditkarte'
        break
      case 'invoice':
        state.method = 'Rechnung'
        break
      case 'sepa':
        state.method = 'Sepa Lastschrift'
        break
      case 'paypal':
        state.method = 'PayPal'
        break
      default:
        state.method = 'error'
    }
  },
  MapArt: (state, art) => {
    switch (art) {
      case 'forever':
        state.art = 'Ewige Kerze'
        break
      case 'premium1':
        state.art = 'Jahreskerze'
        break
      case 'premium2':
        state.art = 'Monatskerze'
        break
      default:
        state.art = 'error'
    }
  },
  MapFrequency: (state, frequency) => {
    switch (frequency) {
      case 'daily':
        state.frequency = 'Täglich'
        break
      case 'biweekly':
        state.frequency = '2x pro Woche'
        break
      case 'weekly':
        state.frequency = 'Wöchentlich'
        break
      default:
        state.frequency = 'error'
    }
  },
  MapUserStatus: (state, userStatus) => {
    switch (userStatus) {
      case 'active':
        state.userStatus = 'Aktiv'
        break
      case 'inactive':
        state.userStatus = 'Inaktiv'
        break
      case 'canceled':
        state.userStatus = 'Gekündigt'
        break
      default:
        state.userStatus = 'error'
    }
  },
  MapAboStatus: (state, aboStatus) => {
    switch (aboStatus) {
      case 'active':
        state.aboStatus = 'Aktiv'
        break
      case 'inactive':
        state.aboStatus = 'Inaktiv'
        break
      case 'canceled':
        state.aboStatus = 'Gekündigt'
        break
      case 'storno':
        state.aboStatus = 'Storniert'
        break
      default:
        state.aboStatus = 'error'
    }
  },
  MapTypeName: (state, typeName) => {
    switch (typeName) {
      case 'memorialPage':
        state.typeName = 'Gedenkseiten'
        break
      case 'memorialMail':
        state.typeName = 'Gedenk-Post'
        break
      case 'candle':
        state.typeName = 'Kerze'
        break
      case 'marketing':
        state.typeName = 'Vermarktung'
        break
      case 'memorialProducts':
        state.typeName = 'Trauerprodukte'
        break
      default:
        state.typeName = 'error'
    }
  },
  MapVisibility: (state, visibility) => {
    switch (visibility) {
      case 'public':
        state.visibility = 'Öffentlich'
        break
      case 'protected':
        state.visibility = 'Nur mit Link'
        break
      case 'private':
        state.visibility = 'Privat'
        break
      default:
        state.visibility = 'error'
    }
  },
  MapPaymentStatus: (state, paymentStatus) => {
    switch (paymentStatus) {
      case 'paid':
        state.paymentStatus = 'Bezahlt'
        break
      case 'storno':
        state.paymentStatus = 'Storniert'
        break
      case 'open':
        state.paymentStatus = 'Offen'
        break
      case 'deadline':
        state.paymentStatus = 'Frist abgelaufen'
        break
      default:
        state.paymentStatus = 'error'
    }
  },
  MapMemorialMailStatus: (state, memorialMailStatus) => {
    switch (memorialMailStatus) {
      case 'active':
        state.memorialMailStatus = 'Aktiv'
        break
      case 'inactive':
        state.memorialMailStatus = 'Gratis Variante'
        break
      case 'canceled':
        state.memorialMailStatus = 'Gekündigt'
        break
      default:
        state.memorialMailStatus = 'error'
    }
  }
}

export default {
  state,
  getters,
  mutations
}
