<template>
  <v-app>
    <v-main style="background-color: #fafafa" class="text--text">
      <header-bar v-if="$route.name && $route.name !== 'Login' && $route.name !== 'OTP' && $route.name !== '2faSetup' && !$route.name.includes('Work')"></header-bar>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  mounted () {
    axios.get('/user')
      .then(res => {
        var data = res.data
        this.$store.commit('setUser', data)
      }).catch(() => {})
  },
  data: () => ({
    //
  })
}
</script>

<style>
  .unvergessen-shadow {
    box-shadow: 0px 0px 15px #3f3f3f2e !important
  }
</style>
