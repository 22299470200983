<template>
  <div class="login">
    <div style="width: 400px; margin: 200px auto;">
      <h1 style="text-align: center; margin-bottom: 20px">Anmelden</h1>
      <v-alert type="error" v-model="error">
        Dein Username oder Passwort ist falsch... Bitte überprüfe deine Eingabe und versuche es erneut
      </v-alert>
      <v-form ref="form" @submit.prevent="login">
        <v-text-field
          label="Username"
          v-model="userName"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="pwd"
          outlined
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show ? 'text' : 'password'"
          name="input-10-1"
          label="Passwort"
          hint="At least 8 characters"
          counter
          @click:append="show = !show"
        ></v-text-field>

        <v-btn type="submit" class="white--text primary" width="100%" height="50px">Anmelden</v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  data () {
    return {
      userName: '',
      pwd: '',
      show: false,
      error: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  methods: {
    login () {
      if (this.$refs.form.validate()) {
        axios.post('/auth/login', { userName: this.userName, pwd: this.pwd })
          .then(res => {
            this.error = false
            this.$store.dispatch('login', res.data)
            this.$store.commit('resetTmpLogin')
            this.$router.push({ name: 'Dashboard' })
          })
          .catch(error => {
            if (error.response.status === 311) {
              const token = 'Bearer ' + error.response.data.token
              axios.defaults.headers.common.Authorization = token
              this.$router.push({ name: '2faSetup' })
            } else if (error.response.status === 310) {
              const token = 'Bearer ' + error.response.data.token
              axios.defaults.headers.common.Authorization = token
              this.$store.commit('setTmpLogin', { username: this.userName, pwd: this.pwd })
              this.$router.push({ name: 'OTP' })
            } else {
              this.error = true
            }
          })
      }
    }
  }
}
</script>
