import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import de from 'vuetify/es5/locale/de'
import Zahlung from '@/assets/custom_icons/Zahlung.vue'
import Nachweis from '@/assets/custom_icons/Nachweis.vue'
import Schloss from '@/assets/custom_icons/Schloss.vue'
import CRM from '@/assets/custom_icons/CRM.vue'
import Candle from '@/assets/custom_icons/Candle.vue'
import Postbox from '@/assets/custom_icons/Postbox.vue'
import News from '@/assets/custom_icons/News.vue'
import GSUser from '@/assets/custom_icons/GSUser.vue'
import Statistics from '@/assets/custom_icons/Statistics.vue'
import Pacman from '@/assets/custom_icons/Pacman.vue'
import CandleSad from '@/assets/custom_icons/CandleSad.vue'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  theme: {
    themes: {
      light: {
        primary: '#febf7c', // '#f8ac5b',
        'primary-hover': '#eb9234',

        secondary: '#fff3e7',
        'secondary-hover': '#ebd9c7',

        text: '#404040',

        orange: '#fba346',
        orange2: '#e19c53',
        orange3: '#febf7c',
        orange4: '#fbcc9a',

        grey: '#bbbbbb',
        grey2: '#d0d0d0',
        grey3: '#e8e8e8',
        grey4: '#fafafa',

        beige: '#eee3d8',

        brown: '#835637',
        brown2: '#9e653e',
        brown3: '#a35418',
        brown4: '#cc7a3b',

        skin: '#bf8e75',
        skin2: '#d3a489',
        skin3: '#eeb08f',
        skin4: '#eab599',
        skin5: '#f6caae',

        gold: '#db9d5c',
        gold2: '#e1a560',
        gold3: '#fbd879',
        gold4: '#fbe096',

        green: '#79c28c',
        green2: '#90dca3',

        red: '#f14242',
        red2: '#f55858',

        success: '#24bf77', // '#47DA6C',
        error: '#F55858'
      }
    }
  },
  icons: {
    values: {
      zahlung: {
        component: Zahlung
      },
      nachweis: {
        component: Nachweis
      },
      schloss: {
        component: Schloss
      },
      crm: {
        component: CRM
      },
      candle: {
        component: Candle
      },
      postbox: {
        component: Postbox
      },
      news: {
        component: News
      },
      gsuser: {
        component: GSUser
      },
      statistics: {
        component: Statistics
      },
      pacman: {
        component: Pacman
      },
      'candle-sad': {
        component: CandleSad
      }
    }
  }
})
