import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import store from '@/store/index'

Vue.use(VueRouter)

var isLoggedIn = store.getters.isLoggedIn

const routes = [{
  path: '/',
  name: 'Login',
  component: Login,
  beforeEnter (to, from, next) {
    if (isLoggedIn || localStorage.ewToken) {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  }
},
{
  path: '/otp',
  name: 'OTP',
  component: () => import('@/views/2fa/OTP.vue'),
  beforeEnter (to, from, next) {
    if (isLoggedIn || localStorage.ewToken) {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  }
},
{
  path: '/setup',
  name: '2faSetup',
  component: () => import('@/views/2fa/2faSetup.vue')
},
{
  path: '/dashboard',
  name: 'Dashboard',
  component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
},
{
  path: '/crm',
  name: 'CRM',
  component: () => import(/* webpackChunkName: "crm" */ '@/views/crm/CRM.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.crm.default) {
      next()
    } else {
      next(from)
    }
  }
},
{
  path: '/crm/complaints',
  name: 'CRMComplaints',
  component: () => import(/* webpackChunkName: "crm" */ '@/views/crm/complaints/Complaints.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.crm.complaints) {
      next()
    } else {
      next(from)
    }
  }
},
{
  path: '/crm/memorial-mail',
  name: 'CRMMail',
  component: () => import(/* webpackChunkName: "crm" */ '@/views/crm/memorial-mail/MemorialMailParent.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.crm.memorialMail && store.getters.user.accessRoles.crm.default) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  },
  children: [{
    path: 'users',
    name: 'CRMMailUserCollection',
    component: () => import('@/views/crm/memorial-mail/children/MemorialMailUserCollection.vue')
  },
  {
    path: 'statistics',
    name: 'CRMMailStatistics',
    component: () => import('@/views/crm/memorial-mail/children/MemorialMailStatistics.vue')
  },
  {
    path: 'crm',
    name: 'CRMMailCRM',
    component: () => import('@/views/crm/memorial-mail/children/MemorialMailCRM.vue')
  },
  {
    path: 'user/:uid',
    name: 'CRMMailSingleUser',
    component: () => import('@/views/crm/memorial-mail/children/MemorialMailSingleUser.vue')
  }]
},
{
  path: '/crm/memorial-page',
  name: 'CRMMemorialPage',
  component: () => import('@/views/crm/memorial-page/MemorialPageParent.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.crm.memorialPage && store.getters.user.accessRoles.crm.default) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  },
  children: [{
    path: 'users',
    name: 'CRMPageUserCollection',
    component: () => import('@/views/crm/memorial-page/children/MemorialPageUserCollection.vue')
  },
  {
    path: 'user/:uid',
    name: 'CRMPageSingleUser',
    component: () => import('@/views/crm/memorial-page/children/MemorialPageSingleUser.vue')
  }
  ]
},
{
  path: '/cases',
  name: 'Cases',
  component: () => import(/* webpackChunkName: "cases" */ '@/views/Cases.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.cases.default) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  }
},
{
  path: '/work',
  name: 'Work',
  component: () => import('@/views/Work.vue'),
  beforeEnter (to, from, next) {
    if (localStorage.uv_a_w === 'true') {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  }
},
{
  path: '/settings',
  name: 'Settings',
  component: () => import('@/views/Settings.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.settings) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  }
},
{
  path: '/statistics',
  name: 'Statistics',
  component: () => import('@/views/statistics/Statistics.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.statistics.default) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  }
},
{
  path: '/statistics/memorialpage',
  name: 'StatisticsMemorialPage',
  component: () => import('@/views/statistics/pages/PageParent.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.statistics.memorialPage && store.getters.user.accessRoles.statistics.default) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  },
  children: [{
    path: 'overview',
    name: 'StatisticsMemorialPageOverview',
    component: () => import('@/views/statistics/pages/children/Pages.vue')
  },
  {
    path: 'detailed/:id',
    name: 'StatisticsMemorialPageDetailed',
    component: () => import('@/views/statistics/pages/children/PageDetailed.vue')
  },
  {
    path: 'detail/:id',
    name: 'StatisticsMemorialPageDetail',
    component: () => import('@/views/statistics/pages/children/DetailedView.vue')
  }]
},
{
  path: '/statistics/crawlers',
  component: () => import('@/views/statistics/crawlers/Skeleton.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.statistics.crawler && store.getters.user.accessRoles.statistics.default) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  },
  children: [{
    path: 'overview',
    name: 'StatisticsCrawlers',
    component: () => import('@/views/statistics/crawlers/children/Overview.vue')
  },
  {
    path: 'today',
    name: 'StatisticsCrawlersToday',
    component: () => import('@/views/statistics/crawlers/children/CrawlerStatsToday.vue')
  },
  {
    path: 'info',
    name: 'StatisticsCrawlersInfo',
    component: () => import('@/views/statistics/crawlers/children/CrawlerStatsInfo.vue')
  },
  {
    path: 'coverage',
    name: 'StatisticsCrawlersCoverage',
    component: () => import('@/views/statistics/crawlers/children/Coverage.vue')
  },
  {
    path: 'errorLog',
    name: 'StatisticsCrawlersErrorLog',
    component: () => import('@/views/statistics/crawlers/children/CrawlerErrorLog.vue')
  },
  {
    path: 'errorLogDetailed',
    name: 'StatisticsCrawlersErrorLogDetailedView',
    component: () => import('@/views/statistics/crawlers/children/CrawlerErrorLogDetailedView.vue')
  }]
},
{
  path: '/finance',
  name: 'Finance',
  component: () => import('@/views/finance/Finance.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.finance.default) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  }
},
{
  path: '/finance/accounting',
  component: () => import('@/views/finance/accounting/Accounting.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.finance.accounting && store.getters.user.accessRoles.finance.default) {
      next()
    } else {
      next(from)
    }
  },
  children: [{
    path: '/',
    name: 'Accounting',
    component: () => import('@/views/finance/accounting/children/Overview.vue')
  },
  {
    path: 'user/:uid',
    name: 'AccountingUser',
    component: () => import('@/views/finance/accounting/children/User.vue')
  }
  ]
},
{
  path: '/finance/controlling',
  component: () => import('@/views/finance/controlling/ControllingParent.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.finance.controlling && store.getters.user.accessRoles.finance.default) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  },
  children: [{
    path: '/',
    name: 'Controlling',
    component: () => import('@/views/finance/controlling/children/Controlling.vue')
  },
  {
    path: ':id',
    name: 'ControllingDetails',
    component: () => import('@/views/finance/controlling/children/ControllingDetails.vue')
  }
  ]
},
{
  path: '/verification',
  name: 'Verification',
  component: () => import('@/views/verification/Verification.vue'),
  beforeEnter (to, from, next) {
    if (store.getters.user.accessRoles.verification) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  }
},
{
  path: '/*',
  redirect: '/'
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
