import axios from 'axios'

const state = {
  user: {}
}

const getters = {
  user: state => {
    return state.user
  },
  admin: state => {
    return state.user.role === 'admin'
  },
  investor: state => {
    return state.user.role === 'investor'
  }
}

const mutations = {
  setUser: (state, payload) => {
    state.user = payload
  },
  setPrename: (state, payload) => {
    state.user.prename = payload
  },
  setSurname: (state, payload) => {
    state.user.surname = payload
  },
  setBirth: (state, payload) => {
    state.user.birthDay = payload
  },
  setEmail: (state, payload) => {
    state.user.workMail = payload
  },
  setUsername: (state, payload) => {
    state.user.userName = payload
  }
}

const actions = {
  setPrename: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/privateData', { newData: { prename: payload } })
        .then(res => {
          context.commit('setPrename', payload)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setSurname: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/privateData', { newData: { surname: payload } })
        .then(res => {
          context.commit('setSurname', payload)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setBirth: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/privateData', { newData: { birth: payload } })
        .then(res => {
          context.commit('setBirth', payload)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setEmail: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/privateData', { newData: { email: payload } })
        .then(res => {
          context.commit('setEmail', payload)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setUsername: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.patch('/user/privateData', { newData: { userName: payload } })
        .then(res => {
          context.commit('setUsername')
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
