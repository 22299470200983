import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router.js'
import store from './store'
import axios from 'axios'
import JwtDecode from 'jwt-decode'
import HeaderBar from '@/components/global/HeaderBar.vue'
import SuccessDialog from '@/components/global/SuccessDialog.vue'

const jwt = require('jsonwebtoken')

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_INTERNAL_URL

const token = jwt.sign({}, process.env.VUE_APP_JWT_KEY, { expiresIn: '168h' })

axios.defaults.headers.common.secret = 'Bearer ' + token

Vue.component('unvergessenSuccessDialog', SuccessDialog)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401 && store.getters.isLoggedIn) {
        store.dispatch('logout')
        router.push({ name: 'Login' })
      }
    }
    throw error
  }
)

if (localStorage.ewToken) {
  const token = localStorage.ewToken
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
  const decodedToken = JwtDecode(token)
  if (decodedToken.userId === null) {
    // empty
  } else {
    store.dispatch('setLoggedIn')
  }
}

router.beforeEach((to, from, next) => {
  if (!store.getters.isLoggedIn && (to.name !== 'Login' && to.name !== '2faSetup' && to.name !== 'OTP')) {
    next({ name: 'Login' })
  } else {
    if (!store.getters.user.accessRoles) {
      axios.get('/user')
        .then(res => {
          var data = res.data
          store.commit('setUser', data)
          next()
        })
        .catch(() => {
          next()
        })
    } else {
      next()
    }
  }
})

Vue.component('HeaderBar', HeaderBar)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
